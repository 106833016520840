import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { isPlatformBrowser } from '@angular/common';
import { Router } from "@angular/router";
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';
import { JwtInterceptor } from './_helpers/http-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './common/shared.module';
import { CommonService } from './services/common.service';
import { TranslateConfigService } from './services/translate-config.service';

// Import the functions you need from the SDKs you need




// // Initialize Firebase
// isSupported().then(() => {
// 	const app = initializeApp(environment.firebaseConfig);
// 	const analytics = getAnalytics(app);
// 	const auth = getAuth(app)
// 	const firestore = getFirestore(app)
// })


export function appInitializer(commonService: any, router: Router, platformId: Object) {
	return () => {
		if (isPlatformBrowser(platformId)) {
			const language = commonService.localStorageGet('locale') || 'en';
			const url = router.url;

			// Only redirect if it's the root URL ('/') and no language prefix is present
			if (url === '/') {
				// Redirect to /en or preferred language
				router.navigateByUrl(`/${language}`);
			} else if (url && !url.startsWith(`/${language}`)) {
				// If the URL does not start with the current language, append the language
				router.navigateByUrl(`/${language}${url}`);
			}
		} else {
			const language = commonService.localStorageGet('locale') || 'en';
			const url = router.url;

			// Same check for server-side (or non-browser) rendering
			if (url === '/') {
				// Redirect to /en or preferred language
				router.navigateByUrl(`/${language}`);
			} else if (url && !url.startsWith(`/${language}`)) {
				// If the URL does not start with the current language, append the language
				router.navigateByUrl(`/${language}${url}`);
			}
		}
	};
}


@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		IonicModule.forRoot({ animated: false }),
		AppRoutingModule,
		SharedModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (HttpLoaderFactory),
				deps: [HttpClient]
			}
		})],
	providers: [
		TranslateConfigService,
		TranslateService,
		FirebaseAnalytics,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: APP_INITIALIZER, useFactory: appInitializer, deps: [CommonService, Router, PLATFORM_ID], multi: true, },
	],

	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/language/", ".json");
}

