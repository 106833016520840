<div class="add-review-container pt-20" [ngClass]="getPlatformService.isDesktop? 'ph-40':null">
	<div class="modal-close ion-text-right" (click)="closeModal()">
		<img src="assets/icon/close.svg" alt="Close" height="100%" width="100%" />
	</div>
	<div class="add-review-header">
		{{'creditComponent.creditOrder' | translate}}
	</div>


	<div *ngIf="isLoading" class="loader-position">
		<ion-spinner name="lines-sharp"></ion-spinner>
		<p>{{'loadingMsg' | translate}}</p>
	</div>

	<div *ngIf="!isLoading" class="content-div">
		<div class="orders-listing mx-content">
			<div class="listing-table">
				<app-loader *ngIf="isLoading"></app-loader>
				<table class="table" *ngIf="!isLoading">
					<thead>
						<th class="column-heading ion-text-center download-col"></th>

						<th class="column-heading order-id-col mw-150 text-left">{{"ordersComponent.order_id"
							| translate}}</th>

						<th class="column-heading ion-text-center status-col mw-120">{{"ordersComponent.paymentMode"|
							translate}}
						</th>

						<th class="column-heading date-col mw-120 text-left">{{"orderDate"
							| translate}}</th>
						<th class="column-heading date-col mw-120 text-left">{{"dueDate"
							| translate}}</th>
						<th class="column-heading date-col mw-120 text-left">{{"creditComponent.overdue"
							| translate}}</th>

						<th class="column-heading ion-text-center status-col mw-120">{{"creditComponent.supplierName"|
							translate}}
						</th>

						<th class="column-heading ship-to-col mw-250 text-left">{{"ordersComponent.ship_to"
							| translate}}</th>
						<th class="column-heading order-total-col mw-120 text-left">
							{{"ordersComponent.order_total"
							| translate}}</th>
						<th class="column-heading ion-text-center status-col mw-120">{{"ordersComponent.countOfItems"|
							translate}}
						</th>
						<th class="column-heading ion-text-center status-col mw-120">{{"ordersComponent.orderStatus"|
							translate}}
						</th>
						<th class="column-heading ion-text-center status-col mw-120">
							{{"ordersComponent.creditApprovalStatus"|
							translate}}
						</th>
						<th class="column-heading ion-text-center status-col mw-120">
							{{"creditComponent.creditPaymentStatus"|
							translate}}
						</th>



					</thead>
					<tbody class="table-body">
						<tr *ngFor="let order of orderList;index as i" class="table-row">
							<td class="ion-text-center">
								<ion-checkbox mode="md" slot="start" [value]="order.order_no"
									(ionChange)="selectCheckbox(order,i,$event)"
									[checked]="order.isChecked"></ion-checkbox>
							</td>
							<td class="order-id text-left fw-600">
								{{order.order_no}}</td>

							<td>
								<ion-button fill="clear" class="order-status-btn">{{(order.payment_type | translate)}}
								</ion-button>
							</td>

							<td class="text-left">{{order.date | date :'MMM dd, YYYY'}}</td>
							<td class="text-left">{{order.last_payment_date | date :'MMM dd, YYYY'}}</td>
							<td class="text-left">{{ getDaysDifference(order.last_payment_date) }}
								{{'creditComponent.Days' | translate}}</td>
							<td class="order-id text-left">
								{{commonService.domDirectionLTR ? order.supplier_name_en : order.supplier_name_ar}}</td>
							<td class="text-left">{{order.ship_to}}</td>
							<td class="text-left">
								{{commonService.currencySymbol}} {{order.order_total ? (order.order_total | number)
								:'-'}}
							</td>
							<td>
								<ion-button fill="clear" class="order-status-btn" *ngIf="false"
									[ngClass]="orderStatus(order)">{{order.order_status
									== 'CANCELLED'
									? ('cancelled' | translate) :order.order_status == 'DISPATCHED' ?
									('dispatched' |
									translate) :order.order_status == 'DELIVERED' ? ('delivered' | translate)
									:('confirmed' |
									translate)}}
								</ion-button>
								{{order.items_count}}
							</td>

							<td>
								<ion-button fill="clear" class="order-status-btn"
									[ngClass]="orderStatus(order.shipping_status)">
									{{order.shipping_status == 'CANCELLED' ? ('cancelled' | translate) :
									order.shipping_status == 'PENDING' ? ('pending' | translate) :
									order.shipping_status == 'APPROVED' ? ('approve' | translate) :
									order.shipping_status == 'REJECTED' ? ('reject' | translate) :
									order.shipping_status == 'DISPATCHED' ? ('dispatched' | translate) :
									order.shipping_status == 'DELIVERED' ? ('delivered' | translate) :
									order.shipping_status == 'NOT_YET_DELIVERED' || order.shipping_status ==
									NOT_DELIVERED ?
									('notYetDelivered' | translate) :
									order.shipping_status == 'RETURNED' ? ('returned' | translate) :
									order.shipping_status == 'CANCELLED' ? ('cancelled' | translate) :
									order.shipping_status == 'CANCELLATION_REQUESTED' ?
									('orderDetailsComponent.cancelRequested'
									| translate) :
									order.shipping_status == 'RETURN REQUESTED' ?
									('orderDetailsComponent.returnRequested' |
									translate) :
									(order.shipping_status | translate)}}
								</ion-button>
							</td>


							<td>
								<ion-button fill="clear" class="order-status-btn"
									[ngClass]="orderStatus(order.credit_approval_status)">
									{{order.credit_approval_status == 'NOT_REQUESTED' ? ('notRequested' | translate) :
									order.credit_approval_status == 'PENDING' ? ('pending' | translate) :
									order.credit_approval_status == 'APPROVED' ? ('approve' | translate) :
									(order.credit_approval_status | translate)}}
								</ion-button>
							</td>
							<td>
								<ng-container *ngIf="order.payment_type == 'CREDIT'">
									<ion-button fill="clear" class="order-status-btn"
										[ngClass]="orderStatus(order.is_credit_fulfilled)">
										{{order.is_credit_fulfilled == false ? ('pending' | translate) :
										('paymentComponent.Paid' | translate)}}
									</ion-button>
								</ng-container>
								<ng-container *ngIf="order.payment_type != 'CREDIT'">

								</ng-container>

							</td>
						</tr>
					</tbody>
				</table>
				<div class="no-record width-100" *ngIf="!orderList.length">
					{{'noOrdersYet' | translate}}
				</div>
			</div>
		</div>



		<!-- ----------------- Button ---------------------- -->

		<div class="login-bottom-container" [ngClass]="[getPlatformService.isDesktop ? '' : 'login-bottom-mobile']"
			*ngIf="from != 'list'">
			<div class="cancel pr-40" (click)="closeModal()">
				{{ 'Cancel' | translate }}
			</div>
			<div class="login-button">
				<ion-button (click)="sendPayment()"
					[disabled]="isLoading  || !selectedIdForCreditPayment || selectedIdForCreditPayment.length < 1">
					{{ 'submit'| translate}}
				</ion-button>
			</div>
		</div>
	</div>
</div>