import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CommonService } from '../services/common.service';

@Injectable({
	providedIn: 'root',
})
export class LanguageGuard implements CanActivate {
	constructor(private router: Router, public commonService: CommonService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const url = state.url;

		// Check if the URL starts with a valid language prefix (e.g., /en/)
		const languageMatch = url.match(/^\/([a-z]{2})\//);

		// If there's no language prefix or it's not valid, redirect
		if (!languageMatch) {
			// Get language from localStorage or default to 'en'
			const defaultLanguage = this.commonService.localStorageGet('locale') || 'en';

			// Avoid redirecting if the URL already contains the default language
			if (!url.startsWith(`/${defaultLanguage}`)) {
				this.router.navigateByUrl(`/${defaultLanguage}${url}`); // Redirect to the correct language
				return false; // Cancel current navigation
			}
		}

		return true; // Allow navigation if language prefix exists
	}
}

