import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { GetLatLongService } from 'src/app/services/get-lat-long';
import { ApiService } from '../../services/api-data.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ModalService } from '../../services/modal.service';
import { QuoteService } from '../../services/quote.service';
import { ToastService } from '../../services/toast.service';

@Component({
	selector: 'app-credit-order-due',
	templateUrl: './credit-order-due.component.html',
	styleUrls: ['./credit-order-due.component.scss'],
	providers: [NavParams]
})
export class CreditOrderDueComponent implements OnInit {
	public isLoading = false;
	private subscription: Subscription = new Subscription();
	isLoggedIn: boolean;
	@Input() value: any;
	@Input() quoteId: any;
	errorMsg: any;
	selectedIdForCreditPayment: any = [];
	orderList: any = [];
	constructor(private modalService: ModalService,
		public getPlatformService: GetPlatformService,
		private formBuilder: FormBuilder,
		public commonService: CommonService,
		private dataService: ApiService,
		private toastService: ToastService,
		private route: ActivatedRoute,
		private navParams: NavParams,
		private quoteService: QuoteService,
		public getLatLongService: GetLatLongService,
		public translate: TranslateService,
		public cartService: CartService,
		private navCtrl: NavController,
		private translateService: TranslateService,
		public alertCtrl: AlertController,
		@Inject(PLATFORM_ID) private platformId: Object,
		public router: Router) {
		this.isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;
	}

	ngOnInit() {
		this.isLoading = true;
		this.getCreditOrder();
	}

	closeModal() {
		this.modalService.dismissModal();
	}


	getCreditOrder() {
		let params = {
			page: 1,
			limit: 100,
			payment_type: 'CREDIT',
			is_credit_payment_initiated: false,
			credit_approval_status: "APPROVED"
		};

		return new Promise((resolve, reject) => {
			this.isLoading = true;
			this.orderList = [];
			this.subscription.add(
				this.dataService.get(URLS.orderList, params).subscribe(
					(data) => {
						if (data && data["code"] == 200) {
							let list =
								data["data"] &&
									data["data"]["result"] &&
									data["data"]["result"].length
									? data["data"]["result"]
									: [];

							list.forEach((element) => {
								let shipTo =
									element.shipping_address +
									(element.shipping_address_city
										? ", " + element.shipping_address_city
										: "") +
									(element.shipping_address_region
										? ", " + element.shipping_address_region
										: "") +
									(element.shipping_address_country
										? ", " + element.shipping_address_country
										: "") +
									(element.shipping_address_zipcode
										? ", " + element.shipping_address_zipcode
										: "");
								let obj = {
									order_status: element.shipping_status
										? element.shipping_status
										: "CONFIRMED",
									order_total: element.total_order_amount
										? element.total_order_amount
										: 0,
									invoice_url: element.invoice_url
										? element.invoice_url
										: null,
									date: element.order_date ? element.order_date : "",
									last_payment_date: element.last_payment_date ? element.last_payment_date : "",
									payment_status: element.payment_status,
									order_no: element.order_no,
									ship_to: shipTo ? shipTo : "",
									invoice_id: element.invoice_id,
									zipcode: element.zipcode,
									items_count: element.item_count ? element.item_count : "-",
									payment_type: element.payment_type ? element.payment_type : "-",
									credit_approval_status: element.credit_approval_status ? element.credit_approval_status : "-",
									shipping_status: element.shipping_status ? element.shipping_status : "-",
									is_credit_payment_initiated: element.is_credit_payment_initiated ? element.is_credit_payment_initiated : "-",
									is_credit_fulfilled: element.is_credit_fulfilled == true ? element.is_credit_fulfilled : false,
									supplier_name_en: element.supplier_name_en ? element.supplier_name_en : "-",
									supplier_name_ar: element.supplier_name_ar ? element.supplier_name_ar : "-",
								};
								this.orderList.push(obj);
							});
						}
						resolve(this.orderList);

						this.isLoading = false;
					},
					(error) => {
						reject();
						this.isLoading = false;
					}
				)
			);
		});
	}


	async sendPayment() {
		const alert = await this.alertCtrl.create({
			header: this.translateService.instant("creditComponent.sendCreditPayment"),
			message: this.commonService.domDirectionLTR ? 'Are you ready to proceed with the payment for your credit order? Please review your order details before confirming.' :
				"هل أنت مستعد لإتمام الدفع لطلب الائتمان الخاص بك؟ يرجى مراجعة تفاصيل الطلب قبل التأكيد.",
			backdropDismiss: true,
			buttons: [
				{
					text: this.commonService.domDirectionLTR ? 'No' : 'لا',
					role: 'cancel',
					handler: () => {
					},
				},
				{
					text: this.commonService.domDirectionLTR ? 'Yes' : 'نعم',
					role: 'confirm',
					handler: () => {
						this.sendPaymentAPICall();
					},
				},
			],
		});
		await alert.present();
	}

	sendPaymentAPICall() {

		this.isLoading = true;
		let params = {
			"invoice_id": this.selectedIdForCreditPayment
		}
		this.subscription.add(
			this.dataService.post(URLS.creditPaymentIntiate, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						let response = data['data'];
						this.modalService.dismissModal();
						this.router.navigate(['/home/order-thank-you'], {
							queryParams: {
								"payment_amount": response.total_amount ? response.total_amount : 0,
								"payment_id": response.saddad_invoice_no ? response.saddad_invoice_no : 0,
								"payment_status": response.payment_status ? response.payment_status : 0,
								"transaction_id": response.saddad_invoice_no ? response.saddad_invoice_no : 0,
								"orderId": response.order_no ? response.order_no : 0,
								"id": response.invoice_id ? response.invoice_id : 0,
								"due_date": response.due_date ? response.due_date : null,
								"biller_id": response.biller_id ? response.biller_id : null,
							}
						});
					} else {
					}
					this.isLoading = false;
				},
				(error) => {
					this.isLoading = false;
				}
			)
		);
	}


	goToProductDetail(data) {
		let prod_id = data.main_product_ref_id;
		let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name : data.product_name_en);
		let catnameUrl = this.commonService.trimUrl(data.category_name_en ? data.category_name_en : data.category_name);
		if (data.product_id) {
			this.navCtrl.navigateRoot("/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id,
				{
					animated: false,
					queryParams: {
						"sub_product_id": data.product_id
					}
				});
		} else {
			this.navCtrl.navigateRoot("/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id,
				{
					animated: false,
				});
		}
	}


	orderStatus(status, productlevel?) {
		switch (status) {
			case "DISPATCHED":
			case "CONFIRMED":
			case "APPROVED":
				if (productlevel) {
					return "btn-green product-status-btn";
				} else {
					return "btn-green";
				}
			case "NOT_REQUESTED":
				{ }
			case "DELIVERED":
				if (productlevel) {
					return "btn-black product-status-btn";
				} else {
					return "btn-black";
				}
			case "NOT_YET_DELIVERED":
			case "NOT_DELIVERED":
			case "CANCELLED":
			case "PENDING":
			case "REJECTED":
			case false:
				if (productlevel) {
					return "btn-red product-status-btn";
				} else {
					return "btn-red";
				}
			default:
				if (productlevel) {
					return "btn-green product-status-btn";
				} else {
					return "btn-green";
				}
		}
	}

	selectCheckbox(item, i, event) {

		item.isChecked = event.detail.checked;

		if (item.isChecked) {
			if (this.selectedIdForCreditPayment && this.selectedIdForCreditPayment.length > 1) {
				// Add the order_no if it's not already in the array
				if (!this.selectedIdForCreditPayment.includes(item.invoice_id)) {
					this.selectedIdForCreditPayment.push(item.invoice_id);
				}
			}
			else {
				this.selectedIdForCreditPayment.push(item.invoice_id);
			}
		} else {
			// Remove the order_no from the array
			this.selectedIdForCreditPayment = this.selectedIdForCreditPayment.filter(invoice_id => invoice_id !== item.invoice_id);
		}

	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	// Add this function to your component
	getDaysDifference(lastPaymentDate: string | Date): number {
		const currentDate = new Date(); // Get the current date and time
		const dueDateObj = new Date(lastPaymentDate); // Parse the due date

		// Calculate the difference in days
		const timeDiff = currentDate.getTime() - dueDateObj.getTime(); // Swap order for expected behavior
		const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

		// Calculate the difference in days
		return dayDiff; // Convert milliseconds to days

	}
}
