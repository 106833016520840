<div class="two-fa-container">
	<div class="modal-close text-right" (click)="closeModal()">
		<img src="assets/icon/close.svg" alt="Close" height="100%" width="100%">
	</div>
	<div class="header-line" *ngIf="!getPlatformService.isDesktop">
	</div>
	<div class="two-fa-header pb-10" *ngIf="step != 2">
		{{'resetPassword' | translate}}
	</div>
	<div class="two-fa-header pb-10" *ngIf="step == 2">
		{{'CreateNewPassword' | translate}}
	</div>
	<div class="two-fa-discription" *ngIf="step != 2">
		{{ "resetPasswordDescription" | translate}}
	</div>
	<div class="two-fa-discription" *ngIf="step == 2">
		{{ "createPasswordDescription" | translate}}
	</div>

	<div class="code-input">
		<form [formGroup]="changePasswordFromGroup">
			<div class="email common-input ion-text-start" *ngIf="step == 1">
				<ion-item lines="none" mode="md">
					<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/email-icon.svg"
						alt="juman">
					<ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{'emailAndPhone' | translate
						}}
					</ion-label>
					<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{'emailAndPhone' | translate
						}}
					</ion-label>
					<ion-input formControlName="email" #emailInput></ion-input>
				</ion-item>
				<ng-container
					*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'email', 'formName': changePasswordFromGroup} }">
				</ng-container>
			</div>

			<div class="password common-input ion-text-start" *ngIf="step != 1">
				<ion-icon name="eye-outline" slot="end" *ngIf="passwordType == 'text'"
					(click)="passwordType = 'password'"
					[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
				<ion-icon name="eye-Off-outline" slot="end" *ngIf="passwordType == 'password'"
					(click)="passwordType = 'text'"
					[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
				<ion-item lines="none" mode="md">
					<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/password-icon.svg"
						alt="juman">
					<ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{ 'NEWPASSWORD' |
						translate }}</ion-label>
					<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{ 'new_password' |
						translate }}</ion-label>
					<ion-input class="registration-pass" type="{{ passwordType }}" formControlName="password"
						(keydown.space)="$event.preventDefault();">
					</ion-input>
				</ion-item>
				<ng-container
					*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'password', 'formName': changePasswordFromGroup} }">
				</ng-container>
			</div>
			<div class="password common-input ion-text-start" *ngIf="step != 1">
				<ion-icon name="eye-outline" slot="end" *ngIf="repeatPasswordType == 'text'"
					(click)="repeatPasswordType = 'password'"
					[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
				<ion-icon name="eye-Off-outline" slot="end" *ngIf="repeatPasswordType == 'password'"
					(click)="repeatPasswordType = 'text'"
					[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
				<ion-item lines="none" mode="md">
					<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/password-icon.svg"
						alt="juman">
					<ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{ 'CONFIRMPASSWORD' |
						translate }}</ion-label>
					<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{ 'confirm_password' |
						translate }}</ion-label>
					<ion-input class="registration-pass" type="{{ repeatPasswordType }}"
						formControlName="repeatPassword" (keydown.space)="$event.preventDefault();">
					</ion-input>
				</ion-item>
				<ng-container
					*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'repeatPassword', 'formName': changePasswordFromGroup} }">
				</ng-container>
			</div>

		</form>

	</div>

	<div class="login-bottom-container" [ngClass]="[getPlatformService.isDesktop ? '' : 'login-bottom-mobile']">
		<div class="cancel pr-40" *ngIf="step == 1 && getPlatformService.isDesktop" (click)="cancelModal()">
			{{ 'CancelClient' | translate }}
		</div>
		<div class="cancel pr-40" *ngIf="step != 1 && getPlatformService.isDesktop"
			(click)="cancelModalResetPassword()">
			{{ 'CancelClient' | translate }}
		</div>
		<div class="login-button" *ngIf="getPlatformService.isDesktop">
			<ion-button *ngIf="step == 1" (click)="openTwoFaModal()" [disabled]="isLoading">
				{{'Submit' | translate}}
			</ion-button>
			<ion-button *ngIf="step != 1" (click)="resetPassword()">
				{{'Save' | translate}}
			</ion-button>
		</div>
		<div class="login-button" *ngIf="!getPlatformService.isDesktop">
			<app-common-button *ngIf="step == 1" color="#FFFFFF" background="#38C2D4" title="{{'Submit' | translate}}"
				[isIcon]="false" [isHideInMobile]="false" [specificHeight]="'48px'" [specificFontWeight]="'bold'"
				[specificFontSize]="'0.9rem'" [isFullWidth]="true" (buttonClicked)="openTwoFaModal()">
			</app-common-button>
			<app-common-button *ngIf="step != 1" color="#FFFFFF" background="#38C2D4" title="{{'Save' | translate}}"
				[isIcon]="false" [isHideInMobile]="false" [specificHeight]="'48px'" [specificFontWeight]="'bold'"
				[specificFontSize]="'0.9rem'" [isFullWidth]="true" (buttonClicked)="resetPassword()">
			</app-common-button>
		</div>
	</div>
</div>

<ng-template let-formObject #validationMessages>
	<ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
		<ion-text color="danger" class="error-msg"
			*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].dirty">
			{{validation.message | translate }}
		</ion-text>
	</ng-container>
</ng-template>