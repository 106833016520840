import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import Swiper from 'swiper';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit, AfterViewInit {

	public pager: boolean = true;
	public slideIndex = 0;
	@ViewChild('topSlides', { static: false }) topSlider!: ElementRef;
	topSliderEl: any;

	topBannerOpts = {
		initialSlide: 0,
		slidesPerView: 1,
		spaceBetween: 10,
		speed: 200,
		autoplay: true,
		loop: true,
		pagination: false,
	};

	middleSlideOpts = {
		initialSlide: 0,
		slidesPerView: 1,
		speed: 200,
		autoplay: true,
		loop: false,
		spaceBetween: 20,
	};

	gridSlideOpts = {
		initialSlide: 0,
		slidesPerView: 1,
		speed: 200,
		autoplay: true,
		loop: false,
		spaceBetween: 20,
	};

	@Input() gridBanner: boolean = false;
	@Input() topBanner;
	@Input() multiBanner;
	@Input() TopBannerData;
	@Input() bottomBannerData;
	@Input() productlistBanner: boolean = false;
	@Input() productTitle: any;
	@Input() gridBannerData: any;
	@Input() bannerImage: any;
	@Input() bannerTitle: any;
	@Input() bannerDescription: any;
	currentLang;
	isLoggedIn = false
	constructor(
		public router: Router,
		private route: ActivatedRoute,
		public getPlatformService: GetPlatformService,
		public commonService: CommonService,
		public navCtrl: NavController,
		public translate: TranslateService,
		private renderer: Renderer2) {
		this.currentLang = this.translate.currentLang;
	}


	ngOnInit() {
		this.isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;

		if (this.productTitle == 'products') {
			this.productTitle = this.commonService.domDirectionLTR ? "Products" : "منتجات"
		}


		setTimeout(() => {
			if (this.TopBannerData && this.TopBannerData.length < 2) {
				this.pager = false;
				// this.topBannerOpts = {
				// 	initialSlide: 0,
				// 	slidesPerView: 1,
				// 	spaceBetween: 10,
				// 	breakpoints: {
				// 		768: {
				// 			slidesPerView: 1,
				// 		},
				// 	},
				// 	speed: 200,
				// 	autoplay: true,
				// 	loop: true,
				// };
			}
			if (this.bottomBannerData && this.bottomBannerData.length < 2) {
				this.middleSlideOpts = {
					initialSlide: 0,
					slidesPerView: 1,
					speed: 200,
					autoplay: true,
					loop: true,
					spaceBetween: 20,
				};
			}
			if (this.gridBannerData && this.gridBannerData.length < 2) {
				this.gridSlideOpts = {
					initialSlide: 0,
					slidesPerView: 1,
					speed: 200,
					autoplay: true,
					loop: true,
					spaceBetween: 20,
				};
			}
			// this.startAutoPlay()
		}, 500)
	}

	ngAfterViewInit() {
		this.topSliderEl = this.topSlider.nativeElement;
		Object.assign(this.topSliderEl, this.topBannerOpts);
		// this.topSliderEl.initialize();
		this.topSliderEl.swiper = new Swiper(this.topSliderEl, this.topBannerOpts);
		console.log('Swiper initialized:', this.topSliderEl.swiper);
	}

	slideTo(direction) {
		if (direction === 'next') {
			this.topSliderEl.swiper.slideNext();
		} else if (direction === 'prev') {
			this.topSliderEl.swiper.slidePrev();
		}
	}

	bannerToProduct(bannerData, redirecton_details, banner_id) {
		let category_id = bannerData.redirecton_details ? bannerData.redirecton_details.category_id : null;
		if (bannerData.is_default_banner) {
			return;
		}
		if (redirecton_details && redirecton_details.product_name) {
			this.commonService.localStorageRemove("category_id");
			if (this.commonService.domDirectionLTR && redirecton_details.product_name_ar) {
				this.navCtrl.navigateForward('/product-listing/' + "products", { animated: false, "state": { "breadcrumb": "products", "url": '/product-listing/' + "products", banner_data: banner_id, banner_img: bannerData.image_url_ar, banner_title: bannerData.banner_title_ar, banner_description: bannerData.banner_description_ar } });
			} else {
				this.navCtrl.navigateForward('/product-listing/' + "products", { animated: false, "state": { "breadcrumb": "products", "url": '/product-listing/' + "products", banner_data: banner_id, banner_img: bannerData.image_url_en, banner_title: bannerData.banner_title_en, banner_description: bannerData.banner_description_en } });
			}
		}
		else if (!this.commonService.domDirectionLTR && redirecton_details && redirecton_details.category_name_ar && category_id) {
			this.navCtrl.navigateForward('/product-listing/' + this.commonService.trimUrl(redirecton_details.category_name_ar) + "/" + category_id, { animated: false, "state": { "breadcrumb": redirecton_details.category_name_ar, "url": '/product-listing/' + this.commonService.trimUrl(redirecton_details.category_name_ar), id: category_id, banner_img: bannerData.image_url_ar, banner_title: bannerData.banner_title_ar, banner_description: bannerData.banner_description_ar } });
		}
		else if (this.commonService.domDirectionLTR && redirecton_details && redirecton_details.category_name_en && category_id) {
			this.navCtrl.navigateForward('/product-listing/' + this.commonService.trimUrl(redirecton_details.category_name_en) + "/" + category_id, { animated: false, "state": { "breadcrumb": redirecton_details.category_name_en, "url": '/product-listing/' + this.commonService.trimUrl(redirecton_details.category_name_en), id: category_id, banner_img: bannerData.image_url_en, banner_title: bannerData.banner_title_en, banner_description: bannerData.banner_description_en } });
		}
		else if (!this.commonService.domDirectionLTR && redirecton_details && redirecton_details.class_name_ar) {
			this.navCtrl.navigateForward('/product-listing/' + this.commonService.trimUrl(redirecton_details.class_name_ar), { animated: false, "state": { "breadcrumb": redirecton_details.class_name_ar, "url": '/product-listing/' + this.commonService.trimUrl(redirecton_details.class_name_ar), banner_data: banner_id, banner_img: bannerData.image_url_ar, banner_title: bannerData.banner_title_ar, banner_description: bannerData.banner_description_ar } });
		}
		else if (this.commonService.domDirectionLTR && redirecton_details && redirecton_details.class_name_en) {
			this.navCtrl.navigateForward('/product-listing/' + this.commonService.trimUrl(redirecton_details.class_name_en), { animated: false, "state": { "breadcrumb": redirecton_details.class_name_en, "url": '/product-listing/' + this.commonService.trimUrl(redirecton_details.class_name_en), banner_data: banner_id, banner_img: bannerData.image_url_en, banner_title: bannerData.banner_title_en, banner_description: bannerData.banner_description_en } });
		}
		else {
			this.commonService.localStorageRemove("category_id");
			if (this.commonService.domDirectionLTR) {
				this.navCtrl.navigateForward('/product-listing/products', { animated: false, "state": { "breadcrumb": "products", "url": '/product-listing/' + "products", banner_data: banner_id, banner_img: bannerData.image_url_en, banner_title: bannerData.banner_title_en, banner_description: bannerData.banner_description_en } });
			} else {
				this.navCtrl.navigateForward('/product-listing/products', { animated: false, "state": { "breadcrumb": "products", "url": '/product-listing/' + "products", banner_data: banner_id, banner_img: bannerData.image_url_ar, banner_title: bannerData.banner_title_ar, banner_description: bannerData.banner_description_ar } });
			}
		}
	}

}
